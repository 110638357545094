<h1>Career Type Identification</h1>

<div id="career-type-content">
<p><strong>Instructions:</strong> Review carefully.</p>
<p><strong>5-7 minutes</strong> to complete.</p>
<p>You will see 15 sets of four pictures.</p>
<p>CHOOSE one work activity that appeals <span class="u">most</span> and one that appeals <span class="u">least</span> for each of the 15 sets.</p>
<p><strong>Come back to this page and SELECT your top two types in the dropdowns below after completing the test. </strong> <span class="i"> (Sample shown below) </span> <a href="http://www.123test.com/career-test" target="_blank"> Take Career Type Test Here </a></p>
<div>

  <form id="career-type-form">
    <ul id="career-type-form-elements">
      <li>
    <label for="primary">Select First Type</label><br />
      <select id="primary" name="primary" (change)="validate()">
        <option value=""> --- </option>
        <option value="Artistic">Artistic</option>
        <option value="Conventional">Conventional</option>
        <option value="Enterprising">Enterprising</option>
        <option value="Investigative">Investigative</option>
        <option value="Realistic">Realistic</option>
        <option value="Social">Social</option>
      </select>
    </li><li>
      <label for="secondary">Select Second Type</label><br />
      <select id="secondary" name="secondary" (change)="validate()">
        <option value=""> --- </option>
        <option value="Artistic">Artistic</option>
        <option value="Conventional">Conventional</option>
        <option value="Enterprising">Enterprising</option>
        <option value="Investigative">Investigative</option>
        <option value="Realistic">Realistic</option>
        <option value="Social">Social</option>
      </select>
      </li>
      <li>
      <button (click)="save();" id="career-type-submit" class="btn btn-primary career-type-submit" style="float:right;margin-right:75px;margin-top:5px;" disabled>Finish and Save</button>
      </li>
    </ul>
    <div class="clear-fix">&nbsp;</div>
      <div id="error-message">The first and secondary types cannot be the same.</div>
  </form>
</div>
<div id="career-type-result-example">
  <img src="../../../assets/images/career_type_text_result_sample.png" alt="The results of the career type test, highlighting the firsst and second highest career type result values." />
</div>
</div>
