import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule  } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ClientCredentials } from '../../models/clientCredentials';
import { AuthenticationService } from '../../services/authentication.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@Component({
selector: 'app-home',
templateUrl: './registration.component.html'

})

export class RegistrationComponent implements OnInit{
   registrationForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService

) {}


ngOnInit() {
  this.registrationForm = this.formBuilder.group({
      email: ['', Validators.required],
      code: ['', Validators.required]
  });

  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
}
  get f() { return this.registrationForm.controls; }

  onSubmit() {
    this.submitted = true;

    const clientCreds = {email: this.f.email.value, code: this.f.code.value};

    this.authenticationService.register(clientCreds)
      .pipe(first()).subscribe(
        data => {
          this.router.navigate(['roadmap']);
        },
        error => { this.error = error; this.loading = false; }

      );

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
        return;
    }

    this.loading = true;

  }
}
