import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { CareerTypeService} from '../../services/career-type.service';
import { CareerTypeInterestOption } from 'src/app/models/career-type-interest-option';
import { CareerTestAnswer } from 'src/app/models/career-test-answer';

@Component({
selector: 'app-home',
templateUrl: './career-type-test.component.html',
styleUrls: ['./career-type-test.component.scss']
})

export class CareerTypeTestComponent implements OnInit {
    
    descriptors: CareerTypeInterestOption[];
    currentPage: number;
    totalPageCount: number;
    buttonText: string;

    constructor(private careerTypeService: CareerTypeService, private router: Router) {
        this.currentPage = 1;
        this.totalPageCount = 30;
    }

    ngOnInit(): void {
        this.getDescriptors(this.currentPage, (data) => {
            this.descriptors = data;
        });
    }

    getDescriptors(page: number, callback: (data) => void) {
        this.careerTypeService.getCareerTypeTestPage(page)
          .subscribe(res => {callback(res); });
      }

    nextPage(){
        
        const mostLikeAnswer = document.querySelector('input[name = "most_preferred"]:checked') as HTMLInputElement;
        const leastLikeAnswer = document.querySelector('input[name = "least_preferred"]:checked') as HTMLInputElement;

        const answer: CareerTestAnswer = { mostLike: mostLikeAnswer.value, leastLike: leastLikeAnswer.value, screen: this.currentPage };
  
        this.careerTypeService.saveCareerTestAnswer(answer).subscribe();

        if (this.currentPage === this.totalPageCount){
            this.router.navigate(['/']);
        }

        this.currentPage++;
        this.getDescriptors(this.currentPage, (data) => {
            this.descriptors = data;
        });
    }

    verifyValid(selectEvent){
        const target = selectEvent.target;
        if (target.checked)  
        {
            const alternate = document.querySelector('#' + this.getAlternateTargetId(target.id)) as HTMLInputElement;
            if (alternate && alternate.checked)
                alternate.checked = false;
        }
    }

    getAlternateTargetId(id: string): string
    {
        if(id.endsWith('0'))
            return id.substring(0, id.length -1) + '1'; 
        else
            return id.substring(0, id.length -1) + '0'; 
    }
}     