import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { DataService } from '../../services/data.service'

import { ClientStatus } from '../../models/client-status'

@Component({
selector: 'app-home',
templateUrl: './home.component.html',
styleUrls: ['./home.component.scss']

})

export class HomeComponent implements OnInit {
    clientStatus: ClientStatus;

    constructor(private dataService: DataService, private router: Router) {}

    getCurrentClientStatus(callback: (data) => void) {
        this.dataService.getClientStatus().subscribe(result => {callback(result)})
    }

    ngOnInit() {
        this.getCurrentClientStatus((data) => {
            this.clientStatus = data;
        })
    }
}


