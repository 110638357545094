import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { CareerTypeComponent } from './components/career-type/career-type.component';
import { CareerTypeTestComponent } from './components/career-type-test/career-type-test.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SkillsAssessmentComponent } from './components/skills-assessment/skills-assessment.component';
import { ValuesInventoryComponent } from './components/values-inventory/values-inventory.component';


import { AuthGuard } from './auth';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
    { path: 'register', component: RegistrationComponent },
    { path: 'career-type', component: CareerTypeComponent, canActivate: [AuthGuard] },
    { path: 'career-type-test', component: CareerTypeTestComponent, canActivate: [AuthGuard] },
    { path: 'skills-assessment', component: SkillsAssessmentComponent, canActivate: [AuthGuard] },
    { path: 'values-inventory', component: ValuesInventoryComponent, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const AppRoutingModule = RouterModule.forRoot(routes, {});
