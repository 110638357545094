import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { User } from '../models/user';
import { ClientStatus } from '../models/client-status';


@Injectable({
    providedIn: 'root',
  })
  export class DataService {
    private currentUser: User;
    private headers: HttpHeaders;
  
    constructor(private http: HttpClient) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
  }
  
  getClientStatus() {
    return this.http.get<any>(`${environment.apiUrl}client/status`,   { headers: this.headers })
    .pipe(catchError(this.handleError<any>('getClientStatus', '')));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      console.error(error);
      return of(result as T);
    };
  }

}