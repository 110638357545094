<h1>YouMap® Values Inventory</h1>
<div id="values-directions">
<p class="subhead">Think of times in life you were happy, proud, and fulfilled.</p>
<ul class="bare-list">
<li>What were you doing? Were you with other people, or alone?</li>
<li>What factors contributed to happiness, pride, and fulfillment?</li>
<li>What did you value about those situations? What did it provide you?</li>
</ul>
<p class="subhead">To prioritize your values:</p>
<p>
Look at the first two and ask, "If I could satisfy only one of these, which would I choose?" For example, “If I could have all the "Accomplishment" I wanted, but no "Freedom", or all the "Freedom" I wanted, but no "Accomplishment", which would I choose?”
</p>
<p>Ask this question until your values are ordered most to least important.
</p>
</div>
<p>Based on the directions above, select your top values from the list and drag them to the right. Select between 5 and 10 values for your top values list. Order them in terms of importance, with the most important values at the top.</p>
<div>
<table id="values-inventory">
  <tr>
    <td>
      <h2>Available Values</h2>
    </td>
    <td>
      <h2>Your Selected Top Values</h2>
    </td>
  </tr>
  <tr>
    <td>
      <div class="values-list"
      cdkDropList
      #ymValuesList="cdkDropList"
      [cdkDropListData]="ymValues"
      [cdkDropListConnectedTo]="[selectedYMValuesList]"
      (cdkDropListDropped)="drop($event)">
      <div  *ngFor="let ymValue of ymValues" cdkDrag class="ymValue">{{ymValue.name}}</div>
    </div>
    </td>
    <td>
      <div class="values-list selected-values"
      cdkDropList
      #selectedYMValuesList="cdkDropList"
      [cdkDropListData]="selectedYMValues"
      [cdkDropListConnectedTo]="[ymValuesList]"
      (cdkDropListDropped)="drop($event)">
      <div  *ngFor="let ymValue of selectedYMValues" cdkDrag class="ymValue">{{ymValue.name}}</div>
    </div>
    </td>
  </tr>
  <tr><td></td><td><button (click)="saveValues();" id="submit-values-btn" class="btn btn-primary" style="float:right;margin-right:75px;margin-top:5px;" disabled>Finish and Save</button></td></tr>
</table>
</div>

