<h1> YouMap® Personality Inventory</h1>
<div id="career-type-test-instructions">
    <p>For each set of descriptors, select the ONE descriptor <strong>most</strong> like you and the ONE <strong>least</strong> like you.</p>
</div>
<div id="career-type-test">
    <table id="descriptors-table">
        <thead>
            <th class="descriptor-column-header">Descriptor</th><th class="option-column-header"><strong>MOST</strong> like you</th><th class="option-column-header"><strong>LEAST</strong> like you</th>
        </thead>
        <tbody>
            <tr *ngFor="let option of descriptors">
                <td class="descriptor-column">
                    {{option.term}}
                </td>
                <td class="option-column">
                    <input type="radio" id="id-{{option.id}}-0" (change)="verifyValid($event)" name="most_preferred" value="{{option.id}}">
                </td>
                <td  class="option-column">
                    <input type="radio" id="id-{{option.id}}-1" (change)="verifyValid($event)" name="least_preferred" value="{{option.id}}">
                </td>
            </tr>
        </tbody>
    </table>
    <div id="career-test-footer"> 
        <button (click)="nextPage();" class="btn btn-primary">Next</button>
        <div id="progress" style="float: right;">{{currentPage}} of {{totalPageCount}}</div>
      </div>
</div>