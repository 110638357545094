<div id="roadmap-contents">
  <p>To begin your YouMap® career profile, complete the strengths assessment, preferred skills and values inventory, and
    career interests profiler, below.</p>
  <ul id="roadmap-steps">
    <li>
      <div class="roadmap-step-number">1</div>
      <div *ngIf="!this.clientStatus?.skillsCompleted">
      <ul class="roadmap-step">
        <li class="roadmap-step-title">Assess the SKILLS you prefer to use most</li>
        <li class="roadmap-step-time">Time to complete: Varies; 15-30 minutes</li>
        <li class="roadmap-step-instructions"><strong>Instructions:</strong>
          <p>Follow the link below to complete an online skills sort exercise. 
            Picture yourself using each skill presented. 
            Choose your response for each skill if you had to perform it <strong>on a regular basis.</strong></p>
        </li>
        <li class="roadmap-step-action"><a href="/skills-assessment">Click to complete the YouMap® Preferred Skills
          Inventory</a></li>
      </ul>
      </div>
      <div *ngIf="this.clientStatus?.skillsCompleted">
        <div class="roadmap-step-completed">
          Congratulations! You've completed the YouMap® skills assessment. This step is done.
        </div>
      </div>
      <div class="clear-fix">&nbsp;</div>
    </li>
    <li>
      <div class="roadmap-step-number">2</div>
      <div *ngIf="!this.clientStatus?.valuesCompleted">
      <ul class="roadmap-step">
        <li class="roadmap-step-title">Assess your top VALUES</li>
        <li class="roadmap-step-time">Time to complete: 20 minutes (average)</li>
        <li class="roadmap-step-instructions"><strong>Instructions:</strong>
          <p>Follow the link below to determine your top 5-10 values.
          </p>
        </li>
        <li class="roadmap-step-action"><a href="/values-inventory">Click to complete the YouMap® Values Inventory</a></li>
      </ul>
      </div>
      <div *ngIf="this.clientStatus?.valuesCompleted">
        <div class="roadmap-step-completed">
          Congratulations! You've completed the YouMap® values assessment. This step is done.
        </div>
      </div>
      <div class="clear-fix">&nbsp;</div>
    </li>
    <li>
      <div class="roadmap-step-number">3</div>
      <div *ngIf="!this.clientStatus?.careerInterestsCompleted">
      <ul class="roadmap-step">
        <li class="roadmap-step-title">Reveal your personality-based INTERESTS</li>
        <li class="roadmap-step-time">Time to complete: 5-10 minutes</li>
        <li class="roadmap-step-instructions"><strong>Instructions:</strong>
          <p>Use the link below to take a personality test. You will see four words presented on each screen. Select one word that <strong>MOST</strong> describes you and one word that <strong>LEAST</strong> describes you..</p>
        </li>
        <li class="roadmap-step-action"><a href="/career-type-test">Click to complete the YouMap® Personality Inventory</a></li>
      </ul>
    </div>
      <div *ngIf="this.clientStatus?.careerInterestsCompleted">
        <div class="roadmap-step-completed">
          Congratulations! You've completed the YouMap® interests assessment. This step is done.
        </div>
      </div>
      <div class="clear-fix">&nbsp;</div>
    </li>
    <li>
      <div class="roadmap-step-number">4</div>
      <ul class="roadmap-step">
        <li class="roadmap-step-title">Discover your top five natural TALENTS</li>
        <li class="roadmap-step-time">Time to complete: 30-45 minutes</li>
        <li class="roadmap-step-instructions"><strong>Please read before beginning:</strong>
          <p class="red-text">If you’ve previously taken CliftonStrengths, please create a new account using a different email. 
            Gallup allows one assessment use per email address. </p> 
            <p>Follow the instructions at the link below to create an account using your 
            <strong>16-digit CliftonStrengths Access Code.</strong> Take the assessment in an environment free of distractions. 
            It is timed and moves to the next question every 20 seconds. </p>
        </li>
        <li class="roadmap-step-action"><a href="https://my.gallup.com/_Home/RedeemAccessCode" target="_blank">CLICK HERE TO BEGIN</a></li>
      </ul>
      <div class="clear-fix">&nbsp;</div>
    </li>
  </ul>
</div>
