import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule  } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ClientCredentials } from '../../models/clientCredentials';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './login.component.html',
})


export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: '';


  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService
  ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
          this.router.navigate(['/']);
        }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
        email: ['', Validators.required],
        code: ['', Validators.required]
    });


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        const clientCreds: ClientCredentials = { email: this.f.email.value, code: this.f.code.value};

        this.authenticationService.login(clientCreds)
          .pipe(first()).subscribe(
            data => {
              this.router.navigate(['/']);
            },
            error => { this.error = error; this.loading = false; }

          );

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
    }
}
