import { Injectable, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { User } from '../models/user';
import { Skill } from '../models/skill';
import { SkillAnswer } from '../models/skillAnswer';


@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  private currentUser: User;
  private headers: HttpHeaders;
  private qsParams: HttpParams;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
}

  getSkills(): Observable<Skill[]> {
    return this.http.get<Skill[]>(`${environment.apiUrl}skills`,  { headers: this.headers })
      .pipe(catchError(this.handleError<Skill[]>('getSkills', [])));
  }

  saveSkill(answer: SkillAnswer) {
    return this.http.post<any>(`${environment.apiUrl}skills/saveskill`, answer,  { headers: this.headers })
    .pipe(catchError(this.handleError<any>('saveSkill', '')));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      return of(result as T);
    };
  }
}


