import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { User } from '../models/user';
import { CareerType } from '../models/career-type';
import { CareerTypeInterestOption } from '../models/career-type-interest-option';
import { CareerTypeTestQuestionComponent } from '../components/career-type-test-question/career-type-test-question.component';
import { CareerTestAnswer } from '../models/career-test-answer';

@Injectable({
  providedIn: 'root',
})
export class CareerTypeService {
  private currentUser: User;
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
}

saveCareerType(careerType: CareerType) {
  return this.http.post<any>(`${environment.apiUrl}careertype/save`, careerType,  { headers: this.headers })
  .pipe(catchError(this.handleError<any>('saveCareerType', '')));
}

saveCareerTestAnswer(answer: CareerTestAnswer){
  return this.http.post<any>(`${environment.apiUrl}careertype/testanswer`, answer,  { headers: this.headers })
    .pipe(catchError(this.handleError<any>('testanswer', '')));
}


getCareerTypeTestPage(page: number): Observable<CareerTypeInterestOption[]> {
  return this.http.get<CareerTypeInterestOption[]>(`${environment.apiUrl}careertype/careertypetestpage`, { headers: this.headers, params:  new HttpParams().set('page', page) })
    .pipe(catchError(this.handleError<CareerTypeInterestOption[]>('getCareerTypeTestPage', [])));
}

private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    console.error(error);
    return of(result as T);
  };
}
}
