import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { User } from '../models/user';
import { YMValue } from '../models/ymvalue';
import { OrderedValue } from '../models/ordered-value';

@Injectable({
  providedIn: 'root',
})
export class ValuesService {
  private currentUser: User;
  private headers: HttpHeaders;
  private qsParams: HttpParams;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
}

  getValues(): Observable<YMValue[]> {
    return this.http.get<YMValue[]>(`${environment.apiUrl}values`)
      .pipe(catchError(this.handleError<YMValue[]>('getValues', [])));
  }

  saveValues(orderedValues: OrderedValue[]) {
    return this.http.post<any>(`${environment.apiUrl}values/save`, orderedValues,  { headers: this.headers })
    .pipe(catchError(this.handleError<any>('saveValues', ''))); 
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      return of(result as T);
    };
  }
}


