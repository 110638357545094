<h1>Login</h1>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <p>Log in using the email address you used to register, and the code your coach has provided to you.</p>
    <div class="form-group">
        <label for="email">Email Address: </label>
        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email address is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="code">Code: </label>
        <input type="text" formControlName="code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.code.errors }" />
        <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
            <div *ngIf="f.code.errors.required">Code is required</div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Login
        </button>
    </div>
    <div class="form-group">
      <p> If you have not yet registered, <a routerLink="/register" class="">register here</a> using the code provided to you by your coach.</p>
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
</form>
