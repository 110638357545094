import {Component, OnInit} from '@angular/core';

import { SkillsService } from '../../services/skills.service';
import { Skill } from '../../models/skill';

@Component({
  selector: 'app-skills-question',
  templateUrl: './skills-question.component.html'
})

export class SkillsQuestionComponent implements OnInit {
  skills: Skill[];
  selectedSkill: Skill;

  constructor(private skillService: SkillsService) { }

  ngOnInit() {
    this.skillService.getSkills().subscribe(skills => this.skills = skills);
  }
}
