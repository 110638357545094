import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Skill } from '../../models/skill';
import { SkillAnswer } from '../../models/skillAnswer';

import { SkillsService } from '../../services/skills.service';

@Component({
  selector: 'app-home',
  templateUrl: './skills-assessment.component.html'
})

export class SkillsAssessmentComponent implements OnInit {

  skills: Skill[];
  selectedSkill: Skill;
  selectedIndex: number;
  currentSkillCount: number;
  totalSkillCount: number;

  constructor(private skillService: SkillsService, private router: Router) {}

  getSkills(callback: (data) => void) {
    this.skillService.getSkills()
      .subscribe(res => {callback(res); });
  }

  ngOnInit() {
    this.getSkills((data) => {
        this.skills = data;
        this.selectedIndex = 0;
        this.currentSkillCount = 1;
        this.totalSkillCount = this.skills.length;
        if (this.skills && this.skills.length > 0) {
          this.selectedSkill = this.skills[this.selectedIndex];
        }
    });
  }

  nextSkill() {
      const skillOption = document.querySelector('input[name = "skillAnswer"]:checked') as HTMLInputElement;
      const answer = { answer: skillOption.value, youMapId:  localStorage.getItem('youMapId'), skillId: this.selectedSkill.id}

      this.skillService.saveSkill(answer).subscribe();

      this.selectedIndex++;
      this.currentSkillCount++;
      if (this.skills && this.skills.length > this.selectedIndex) {
        this.selectedSkill = this.skills[this.selectedIndex];
        skillOption.checked = false;
      } else {
        this.router.navigate(['/']);
      }
  }

}
