import {Component, OnInit} from '@angular/core';

import { CareerTypeService } from '../../services/career-type.service';
import { CareerTypeInterestOption } from '../../models/career-type-interest-option';

@Component({
  selector: 'career-type-test-question',
  templateUrl: './career-type-test-question.component.html'
})

export class CareerTypeTestQuestionComponent implements OnInit {
  careerTypeOptions: CareerTypeInterestOption[];
  mostLikeOption: CareerTypeInterestOption;
  leastLikeOption: CareerTypeInterestOption;

  constructor(private service: CareerTypeService) { }

  ngOnInit() {
    
  }
}
