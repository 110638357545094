import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import { CareerTypeComponent } from './components/career-type/career-type.component';
import { CareerTypeTestComponent } from './components/career-type-test/career-type-test.component';
import { CareerTypeTestQuestionComponent } from './components/career-type-test-question/career-type-test-question.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SkillsAssessmentComponent } from './components/skills-assessment/skills-assessment.component';
import { SkillsQuestionComponent} from './components/skills-question/skills-question.component';
import { ValuesInventoryComponent } from './components/values-inventory/values-inventory.component';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './auth';

@NgModule({
  declarations: [
    AppComponent,
    CareerTypeComponent,
    CareerTypeTestComponent,
    CareerTypeTestQuestionComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    NavMenuComponent,
    RegistrationComponent,
    SkillsAssessmentComponent,
    SkillsQuestionComponent,
    ValuesInventoryComponent,

  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    RouterModule.forRoot([], {})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
