<h1>Create a new account</h1>

<div id="registration-form">
  <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="email">The email address you would like to use to log-in to the site:
      </label>
      <input type="text" formControlName="email" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Email address is required</div>
      </div>
    </div>
    <div class="form-group">
      <label for="code">The code you received from your coach: </label>
      <input type="text" formControlName="code" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.code.errors }" />
      <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
        <div *ngIf="f.code.errors.required">Code is required</div>
      </div>
    </div>
    <div class="form-group">
      <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Register
      </button>
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
  </form>
</div>
