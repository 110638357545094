import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CareerType } from '../../models/career-type';

import { CareerTypeService} from '../../services/career-type.service';

@Component({
selector: 'app-home',
templateUrl: './career-type.component.html',
styleUrls: ['./career-type.component.scss']
})

export class CareerTypeComponent {

  constructor(private careerTypeService: CareerTypeService, private router: Router) {}

  save(): void {
    const primaryCareerType =  (document.getElementById('primary') as HTMLSelectElement).value;
    const secondaryCareerType =  (document.getElementById('secondary') as HTMLSelectElement).value;
    const careerType: CareerType = { primary: primaryCareerType, secondary: secondaryCareerType};
    this.careerTypeService.saveCareerType(careerType).subscribe();
    this.router.navigate(['/']);
  }

  validate(): void {
    let submitDisabled = true;
    const primaryCareerType = (document.getElementById('primary') as HTMLSelectElement).value;
    const secondaryCareerType = (document.getElementById('secondary') as HTMLSelectElement).value;
    if (primaryCareerType !== '' && secondaryCareerType !== '' && primaryCareerType !== secondaryCareerType) {
      submitDisabled = false;
   }

   if (primaryCareerType === secondaryCareerType) {
    document.getElementById('error-message').style.setProperty('display', 'block');
   } else {
    document.getElementById('error-message').style.setProperty('display', 'none');
  }

   (document.getElementById('career-type-submit') as HTMLButtonElement).disabled = submitDisabled;
  }
}
