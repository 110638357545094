<h1>YouMap® Preferred Skills Inventory</h1>
<p>Picture yourself using each skill presented. Choose your response for each skill if you had to perform it <strong>on a regular basis.</strong> Please provide the <strong>best</strong> answer for each of the following questions. </p>
<form id="skills-assessment">
  <h2 id="skill-name">{{selectedSkill.name}}</h2>
  <div id="skill-description">{{selectedSkill.description}}</div>
  <div id="skills-answer-group">
    <div>
      <input type="radio" id="Preferred1" name="skillAnswer" value="Preferred">
      <label for="Preferred1" class="radio-label"> I ENJOY using this skill REGULARLY and I am GOOD at it</label>
    </div>
    <div>
      <input type="radio" id="NotPreferred1" name="skillAnswer" value="NotPreferred">
      <label for="NotPreferred1" class="radio-label"> I DISLIKE using this skill REGULARLY, but I am GOOD at it</label>
    </div>
    <div>
      <input type="radio" id="NotPreferred2" name="skillAnswer" value="NotPreferred">
      <label for="NotPreferred2" class="radio-label"> I DISLIKE using this skill REGULARLY, and I am NOT GOOD at it</label>
    </div>
    <div>
      <input type="radio" id="Preferred2" name="skillAnswer" value="Preferred">
      <label for="Preferred2" class="radio-label"> I have not used this skill but imagine I would LIKE using it REGULARLY</label>
    </div>
    <div>
      <input type="radio" id="NotPreferred3" name="skillAnswer" value="NotPreferred">
      <label for="NotPreferred3" class="radio-label"> I have not used this skill but imagine I would DISLIKE using
        it REGULARLY</label>
    </div>
    <div>
      <button (click)="nextSkill();" class="btn btn-primary">Next</button>
      <div id="progress" style="float: right;">{{currentSkillCount}} of {{totalSkillCount}}</div>
    </div>
  </div>
</form>
