import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { YMValue } from '../../models/ymvalue';

import { ValuesService } from '../../services/values.service';
import { OrderedValue } from '../../models/ordered-value';


@Component({
selector: 'app-home',
templateUrl: './values-inventory.component.html'
})

export class ValuesInventoryComponent implements OnInit {
  ymValues: YMValue[];
  selectedYMValues: YMValue[];

  constructor(private valuesService: ValuesService, private router: Router) {}

  getValues(callback: (data) => void) {
    this.valuesService.getValues()
      .subscribe(res => {callback(res); });
  }

  ngOnInit() {
    this.getValues((data) => {
        this.ymValues = data;
        this.ymValues.sort((a, b) => a.name.localeCompare(b.name));
    });
    this.selectedYMValues = [] as YMValue[];
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.container.id === 'cdk-drop-list-1' && this.selectedYMValues.length === 10)
      {
        alert('No more than 10 values may be selected.  To select additional values, please move other, lower priority values back out of the "Your Selected Top Values" list to the "Avaliable Values" list');
        this.ymValues.sort((a, b) => a.name.localeCompare(b.name));
        return;
      }
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);

      if (this.selectedYMValues.length >= 5) {
        (document.getElementById('submit-values-btn') as HTMLButtonElement).disabled = false;
      }
    }
    this.ymValues.sort((a, b) => a.name.localeCompare(b.name));
  }

  saveValues(){
    let orderedValues: OrderedValue[] = [];
    for(let i = 0; i < this.selectedYMValues.length; i++) {
        const val: OrderedValue = {value: this.selectedYMValues[i], orderId: i};
        orderedValues.push(val);

    }
    this.valuesService.saveValues(orderedValues).subscribe();
    this.router.navigate(['/']);

  }


}
